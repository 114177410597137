$(function(){
  $('.slider').slick({
      autoplay: true, //自動でスクロール
      initialSlide: 0, //初期表示スライドを指定
      autoplaySpeed: 0, //自動再生のスライド切り替えまでの時間を設定
      speed: 12000, //スライドが流れる速度を設定
      cssEase: "linear", //スライドの流れ方を等速に設定
      slidesToShow: 4, //表示するスライドの数
      swipe: false, // 操作による切り替えはさせない
      arrows: false, //矢印非表示
      pauseOnFocus: true, //スライダーをフォーカスした時にスライドを停止させるか
      pauseOnHover: true, //スライダーにマウスホバーした時にスライドを停止させるか
      responsive: [{
            breakpoint: 1080,
            settings: {
              autoplay: true, //自動でスクロール
              autoplaySpeed: 0, //自動再生のスライド切り替えまでの時間を設定
              pauseOnFocus: false, //スライダーをフォーカスした時にスライドを停止させるか
              pauseOnHover: true, //スライダーにマウスホバーした時にスライドを停止させるか
              speed: 10000,
              slidesToShow: 3
            }
        },{
          breakpoint: 800,
          settings: {
            autoplay: true, //自動でスクロール
            autoplaySpeed: 0, //自動再生のスライド切り替えまでの時間を設定
            pauseOnFocus: false, //スライダーをフォーカスした時にスライドを停止させるか
            pauseOnHover: true, //スライダーにマウスホバーした時にスライドを停止させるか
            speed: 6000,
            slidesToShow: 2.5
          }
        },{
          breakpoint: 500,
          settings: {
            autoplay: true, //自動でスクロール
            autoplaySpeed: 0, //自動再生のスライド切り替えまでの時間を設定
            pauseOnFocus: false, //スライダーをフォーカスした時にスライドを停止させるか
            pauseOnHover: true, //スライダーにマウスホバーした時にスライドを停止させるか
            speed: 6000,
            slidesToShow: 1.3
          }
        }
      ],
    });
});
